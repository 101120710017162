// extracted by mini-css-extract-plugin
export var Footer = "Q_cK";
export var aniBgLoading = "Q_f";
export var aniSpin = "Q_d";
export var column = "Q_bk";
export var columns = "Q_cN";
export var heading = "Q_C";
export var link = "Q_bl";
export var main = "Q_c";
export var nav = "Q_bj";
export var newsletter = "Q_cP";
export var newsletter__heading = "Q_cQ";
export var scrollTop = "Q_cL";
export var scrollTop__btn = "Q_cM";